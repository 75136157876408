<template>
  <span>
    <n-text preset="label-2" color="grey-dark" v-if="withLabel">
      {{ $t('main.searchHeader.seats') }}
    </n-text>

    <n-dropdown
      :icon="withIcon ? 'person' : ''"
      :value="getSelectedSeats()"
      :options="seatOptions"
      @input="handleChange"
    />
  </span>
</template>
<script>
export default {
  props: {
    value: {
      type: Number,
      required: false,
    },
    withLabel: {
      type: Boolean,
      default: true,
    },
    withIcon: {
      type: Boolean,
      default: false,
    },
    min: {
      type: Number,
      default: 1,
    },
    max: {
      type: Number,
      default: 4,
    },
  },
  data: function() {
    return {selectedSeats: this.value};
  },
  computed: {
    seatOptions() {
      const offSet = this.min - 1;
      const seatIndex = this.value ? this.value - 1 - offSet : 0;
      const availableSeats = Array(this.max - offSet)
        .fill(null)
        .map((_, i) => {
          return {
            id: i + 1 + offSet,
            text: `${this.$tc('c.searchHeader.seats', i + 1 + offSet)}`,
          };
        });
      this.$emit('input', availableSeats[seatIndex].id);
      return availableSeats;
    }
  },
  methods: {
    handleChange(event) {
      this.selectedSeats = this.seatOptions.find((option) => option.id === event.id).id;
      this.$emit('input', this.selectedSeats);
    },
    getSelectedSeats() {
      return this.seatOptions.find(option => option.id === this.selectedSeats);
    }
  },
  watch: {
    value(newValue) {
      this.selectedSeats = this.seatOptions.find((option) => option.id === newValue).id;
    },
  },
};
</script>
