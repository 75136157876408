<template>
  <div class="vertical-schedule">
    <div
      v-for="(leg, index) in legs"
      :key="index"
      class="leg"
    >
      <div
        class="row"
        :class="[isCarLegType(leg.type) ? 'accent' : 'grey-dark', leg.type]"
      >
        <div
          v-if="index === 0 && !isCarLegType(leg.type)"
          class="grey first"
        />
        <n-icon
          v-else-if="isCarLegType(leg.type)"
          :name="icon(leg.type)"
          size="sm"
          type="circle"
          color="accent"
          class="line-icon align-start"
        />
        <n-icon
          v-else
          name="target-spot"
          class="line-icon align-start"
          size="sm"
          :color="isCarLegType(legs[index - 1].type) ? 'accent' : 'grey-dark'"
        />
        <n-grid
          class="info"
          :row-gap="0"
        >
          <div class="timeline">
            <n-text
              preset="label-3"
              color="grey-dark"
            >
              {{ formatTime(leg.origin.datetime) }}
            </n-text>
            <n-icon
              :name="icon(leg.type)"
              color="grey-dark"
              size="sm"
              class="flex-start align-center leg-icon"
            />
          </div>
          <div class="trip-destination">
            <n-text
              preset="body"
              class="span-5"
            >
              {{ leg.origin.name }}
            </n-text>

            <n-text
              preset="sub"
              color="grey-dark"
              class="span-5"
            >
              {{ legSubText(leg) }}
            </n-text>
          </div>
        </n-grid>
      </div>
      <!-- FINAL DESTINATION -->
      <div
        v-if="legs.length - 1 === index"
        class="destination"
      >
        <n-icon
          name="target-spot"
          size="sm"
          type="circle-inverse"
          class="line-icon align-start last"
          :color="isCarLegType(leg.type) ? 'accent' : 'grey-dark'"
        />
        <n-grid
          class="info"
          :row-gap="0"
        >
          <n-text
            preset="label-3"
            color="grey-dark"
          >
            {{ formatTime(leg.destination.datetime) }}
          </n-text>
          <n-text
            preset="body"
            class="span-5"
          >
            {{ leg.destination.name }}
          </n-text>
        </n-grid>
      </div>
    </div>
  </div>
</template>

<script>
import constants from '@/constants';
import { format, formatDistanceStrict } from '@/vendor/date-fns';

export default {
  name: 'VerticalScheduleTrip',
  components: {},
  props: {
    legs: {
      type: Array,
      default: () => [],
    },
    destination: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    filteredLegs: [],
  }),
  methods: {
    formatTime(date) {
      return format(new Date(date), 'HH:mm');
    },
    icon(type) {
      return constants.transportIcons[type];
    },
    legDuration(leg) {
      return formatDistanceStrict(
        new Date(leg.origin.datetime),
        new Date(leg.destination.datetime)
      );
    },
    isCarLegType(leg) {
      return leg.type === 'CAR';
    },
    extractDistance(distanceInMeters) {
      const distance =
        distanceInMeters > 1000 ? distanceInMeters / 1000 : distanceInMeters;

      return Number.isInteger(distance)
        ? distance + ' m'
        : parseFloat(distance).toFixed(1) + ' km';
    },
    legSubText(leg) {
      const text = this.legDuration(leg);
      const distance = this.extractDistance(leg.distance);

      return `${text} ${!isNaN(distance) ? `(${distance})` : ''}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/style/variables.scss';
.vertical-schedule {
  .leg {
    padding-left: 8px;
    .row {
      border-left: solid 2px;
      position: relative;
      padding-bottom: 10px;
      &.grey {
        border-color: var(--color-grey-dark);
      }
      &.accent {
        border-color: var(--color-accent);
      }
      &.WALK {
        border-left-style: dashed;
      }
      .line-icon {
        position: absolute;
        left: calc(-0.55 * var(--icon-sm-size));
        top: -2px;
      }
      .first {
        border: solid var(--color-grey-dark) 2px;
        background: white;
        border-radius: 100%;
        position: absolute;
        left: calc(-0.5 * var(--icon-sm-size) + 1px);
        height: calc(var(--icon-sm-size) - 3px);
        width: calc(var(--icon-sm-size) - 3px);
        &.accent {
          border-color: var(--color-accent);
        }
      }
      .info {
        padding: 0 20px;
        display: flex;

        .timeline {
          width: 36px;
          display: flex;
          flex-shrink: 0;
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
        }

        .trip-destination {
          flex-grow: 1;
          display:flex;
          flex-direction: column;
        }

        .leg-icon {
          height: unset;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-grow:1;
        }
      }
    }
  }
  .destination {
    padding-left: 20px;
    position: relative;
    .line-icon {
      position: absolute;
      left: calc(-0.5 * var(--icon-sm-size) + 1px);
    }
  }
}
</style>
