<template>
  <span>
    <n-text preset="label-2" color="grey-dark">
      {{ $t('main.provide.dropdownCharge.header') }}
      <n-help :text="helpText" />
    </n-text>
    <n-dropdown :value="getOption()" :options="options" @input="handleChange" />
  </span>
</template>

<script>
import i18n from '@/i18n';
import { mapState } from 'vuex';
import { namespace as userNamespace } from '@/store/modules/user-types';

export default {
  props: {
    value: {
      type: Boolean,
      required: false,
    },
  },
  data: function() {
    return {selectedOption: this.value ? 'charge' : 'onlySubsidy'};
  },
  computed: {
    ...mapState(userNamespace, ['profile']),
    helpText() {
      return i18n.t('main.provide.dropdownCharge.helpModal');
    },
    options() {
      return [
        {
          id: 'charge',
          text: i18n.t('main.provide.dropdownCharge.positive')
        },
        {
          id: 'onlySubsidy',
          text: i18n.t('main.provide.dropdownCharge.negative'),
        }];
    }
  },
  methods: {
    handleChange(event) {
      this.selectedOption = this.options.find(option => option.id === event.id).id;
      this.$emit('input', this.selectedOption === 'charge');
    },
    getOption() {
      return this.options.find(option => option.id === this.selectedOption);
    }
  },
  watch: {
    value(newValue) {
      this.selectedOption = newValue ? 'charge' : 'onlySubsidy';
    }
  }
};
</script>
