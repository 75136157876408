<template>
  <n-theme type="driver" class="view-container">
    <n-nav-bar :title="$t('main.provide.tripOptions.title')" />

    <n-scrollable>
      <n-layout>
        <trip-options class="span-6" :from="trip.from_street" :to="trip.to_street" v-model="options" :default-time="time" :withSeats="true" :seats="trip.seats" />
        <n-button @click="create" :loading="creating" size="lg" block>{{ $t('main.provide.tripOptions.create') }}</n-button>
      </n-layout>
    </n-scrollable>

    <offer-return-trip-dialog ref="offerReturnTripDialog" />
    <n-dialog />
  </n-theme>
</template>

<script>

import * as util from '@/vendor/utils';
import commuteApi from '@/api/commute';
import { EventBus } from '@/vendor/events';
import TripOptions from '@/components/shared/tripOptions';
import OfferReturnTripDialog from '@/dialogs/offerReturnTripDialog';
import i18n from '@/i18n/index';
import { sentryException } from '@/vendor/sentry';
import { ignoreHandledErrors } from '@/vendor/axios';

export default {
  name: 'mainProvideCreateRecurringOptions',
  components: {
    TripOptions,
    OfferReturnTripDialog
  },
  props: {
    trip: {
      type: Object,
      required: true,
    },
    time: { },
    from: {
      type: Object,
      required: false
    },
    to: {
      type: Object,
      required: false
    },
    return: {
      type: Boolean,
      default: true,
    },
  },
  beforeRouteEnter(to, from, next) {
    if (!to.params.trip) {
      next({ name: 'main.provide' });
      return;
    }

    next();
  },
  data() {
    return {
      creating: false,
      options: {
        seats: this.trip.seats,
      },
    }
  },
  methods: {
    async create() {
      if (this.creating) {
        return;
      }

      const recurringTripData = {
        ...this.trip,
        ...util.parseRecurringOptions(this.options),
        seats: this.options.seats,
      }

      this.creating = true;

      commuteApi.postDriverTrip(recurringTripData)
        .then(() => this.onSavedSuccessfully())
        .catch(ignoreHandledErrors)
        .catch(error => {
          if (error?.response?.status === 422) {
            sentryException(error);

            this.$error(i18n.t('main.provide.tripOptions.unprocessableError'));
            this.$router.resetToHome();
            return;
          }
          throw error;
        })
        .finally(() => {
            this.creating = false;
        });
    },
    onSavedSuccessfully() {
      EventBus.$emit('fetch-recurring-overview');

      if (this.return) {
        this.onDontCreateReturnTrip();
        return;
      }

      this.$refs.offerReturnTripDialog.show(
        // yes
        () => {
          const { from, to } = this;

          this.$router.resetToHome();
          this.$router.push({ name: 'main.provide', params: { from: to, to: from, return: true } });
        },

        // no
        () => {
          this.onDontCreateReturnTrip();
        }
      );
    },
    onDontCreateReturnTrip() {
      this.$router.resetToHome();
      this.$success(this.$t('main.provide.recurringTripCreated'));
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
