export const bookingStatus = {
  ACCEPTED: 'ACCEPTED',
  DENIED: 'DENIED',
  AWAITING_APPROVAL: 'AWAITING_APPROVAL',
  RESERVED: 'RESERVED',
  CANCELED: 'CANCELED',
};

export const commuteBookingStatus = {
  accepted: 'ACCEPTED',
  denied: 'DENIED',
  awaitingApproval: 'AWAITING_APPROVAL',
  canceled: 'CANCELED',
};

export const defaultSeats = 4;

export default {
  defaultSeats: defaultSeats,
  defaultTripTimes: {
    departure: {
      daysOffset: 1,
      hours: 8,
      minutes: 0,
    },
    return: {
      daysOffset: 1,
      hours: 16,
      minutes: 0,
    },
  },
  commuteTypes: {
    work: 'WORK',
    otherRecurring: 'OTHER',
    singleTrip: 'SINGLE_TRIP',
  },
  timeTypes: {
    departure: 'DEPARTURE',
    arrival: 'ARRIVAL',
  },
  demandDefaultReturnTime: {
    hours: 16,
    minutes: 0,
  },
  commuteBookingStatus,
  commuteRequestStatus: {
    open: 'OPEN',
    awaitingApproval: 'AWAITING_APPROVAL',
    booked: 'BOOKED',
    stalled: 'STALLED',
    publicTransport: 'PUBLIC_TRANSPORT',
    canceled: 'CANCELED',
  },
  denmarkLatLng: {
    lat: 56.2265414,
    lng: 10.4217051,
  },
  transportIcons: {
    IC: 'train',
    LYN: 'train',
    REG: 'train',
    S: 'train',
    TOG: 'train',
    TRAM: 'train',
    SUBWAY: 'train',
    BUS: 'bus',
    EXB: 'bus',
    NB: 'bus',
    TB: 'car',
    M: 'train',
    WALK: 'person-walking',
    TRAIN: 'train',
    CAR: 'car',
    FLEXTUR: 'car',
    JNY: 'bus', // Trafiklab does not have multiple atm,
    LET: 'train',
    LETBANE: 'train',
    FOOT: 'person-walking',
    METRO: 'train',
    RAIL: 'train',
    SUB: 'train',
    GEN: 'train',
    COMM: 'train',
  },
  weekdays: [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
  ],
  bookingStatus,
  tripTypes: {
    planned: 'PLANNED',
    onDemand: 'ON_DEMAND',
  },
  tripActivation: {
    gracePeriodMinutes: 15,
  },
  publicTransportProviderLogos: {
    REJSEPLANEN:
      process.env.VUE_APP_ROOT_URL +
      '/images/transportproviders/rejseplanen.png',
    ENTUR:
      process.env.VUE_APP_ROOT_URL + '/images/transportproviders/entur.png',
    TRAFIKLAB:
      process.env.VUE_APP_ROOT_URL + '/images/transportproviders/trafiklab.png',
    NEGENTWEE:
      process.env.VUE_APP_ROOT_URL + '/images/transportproviders/negentwee.png',
  },
};

export const permission = {
  PUSH: 'requestPushPermission',
  LOCATION: 'requestLocationPermission',
};

/**
 * Days to generate commute driver trips or commute requests
 * must match /app/Project/Commute/CommuteRecurringTrip.php (GENERATE_PERIOD)
 */
export const TRIP_GENERATION_DAYS = 7; // must match CommuteRecurringTrip::GENERATE_PERIOD

export const onDemandOptions = {
  locationLifetime: 45,
};
