import icon from 'vue-svgicon';

// md-icons
import './assets/md-icons/account';
import './assets/md-icons/car-seat';
import './assets/md-icons/account-outline';
import './assets/md-icons/arrow-left';
import './assets/md-icons/arrow-right';
import './assets/md-icons/calendar-blank-outline';
import './assets/md-icons/calendar-edit';
import './assets/md-icons/check';
import './assets/md-icons/check-circle';
import './assets/md-icons/checkbox-blank-circle-outline';
import './assets/md-icons/chevron-down';
import './assets/md-icons/chevron-left';
import './assets/md-icons/chevron-right';
import './assets/md-icons/chevron-up';
import './assets/md-icons/close';
import './assets/md-icons/compass';
import './assets/md-icons/content-save';
import './assets/md-icons/crosshairs-gps';
import './assets/md-icons/delete';
import './assets/md-icons/file-document';
import './assets/md-icons/flag';
import './assets/md-icons/format-list-bulleted';
import './assets/md-icons/help-rhombus';
import './assets/md-icons/help-rhombus-outline';
import './assets/md-icons/home';
import './assets/md-icons/map';
import './assets/md-icons/map-plus';
import './assets/md-icons/map-marker';
import './assets/md-icons/map-marker-multiple';
import './assets/md-icons/menu';
import './assets/md-icons/menu-down';
import './assets/md-icons/menu-right';
import './assets/md-icons/message-text-outline';
import './assets/md-icons/minus-circle';
import './assets/md-icons/office-building';
import './assets/md-icons/pencil';
import './assets/md-icons/plus';
import './assets/md-icons/recycle';
import './assets/md-icons/repeat';
import './assets/md-icons/send';
import './assets/md-icons/star';
import './assets/md-icons/star-half';
import './assets/md-icons/star-outline';
import './assets/md-icons/store';
import './assets/md-icons/truck';
import './assets/md-icons/undo';
import './assets/md-icons/undo-variant';
import './assets/md-icons/person';
import './assets/md-icons/directions_car';
import './assets/md-icons/calendar-multiple';
import './assets/md-icons/calendar-check';
import './assets/md-icons/help-circle-outline';
import './assets/md-icons/credit-card';
import './assets/md-icons/dots-horizontal';
import './assets/md-icons/refresh';
import './assets/md-icons/add-a-photo';

// require custom icons
const regex = /\.\/(.*)\.js/;
const requireContext = require.context('./assets/icons', false, /.*\.js$/);

requireContext.keys().forEach((file) => {
  const iconName = file.match(regex)[1];

  icon.register({
    [`ng-${iconName}`]: requireContext(file).default,
  });
});

// require st icons
const requireContextSt = require.context('./assets/n-icons', false, /.*\.js$/);

requireContextSt.keys().forEach((file) => {
  const iconName = file.match(regex)[1];

  icon.register({
    [`${iconName}`]: requireContextSt(file).default,
  });
});

// require figma icons
const requireContextFigma = require.context('./assets/figma-icons', false, /.*\.js$/);

requireContextFigma.keys().forEach((file) => {
  const iconName = file.match(regex)[1];

  icon.register({
    [`n_figma-${iconName}`]: requireContextFigma(file).default,
  });
});
