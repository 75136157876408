<template>
  <n-top-safe-spacer
    class="nav-bar-safe-spacer shadow"
    :class="{ shadowless: shadowless }"
  >
    <div class="nav-bar">
      <div @click="goBack" class="back-button">
        <n-icon
          name="figma-chevron-left"
          class="flex-start"
          color="inherit"
          size="md"
          outline
        />
      </div>
      <div class="title">
        <slot name="title">
          <n-text-model size="lg" weight="medium" class="span-5 text-ellipsis">
            {{ title }}
          </n-text-model>
        </slot>
      </div>

      <div class="spacer" />

      <n-spinner
        v-if="loading"
        class="self-end"
        :color="solid ? 'white' : 'accent'"
      />
    </div>
  </n-top-safe-spacer>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    shadowless: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    goBackOverride: {
      type: Function,
      default: null,
    },
  },
  methods: {
    goBack() {
      if (this.goBackOverride) {
        return this.goBackOverride();
      }
      return this.$router.back();
    },
  },
};
</script>
<style lang="scss" scoped>
.nav-bar-safe-spacer {
  .nav-bar {
    z-index: 8;
    padding: 1.8rem var(--grid-padding-x) 1.5rem var(--grid-padding-x);
    display: flex;
    justify-content: space-around;
    align-items: center;

    .back-button {
      height: 3.75rem;
      width: 3.75rem;
      margin: -1.25rem;
      padding: 1.25rem;

      --color: #b5b4b4;
    }

    .title {
      flex: 1 1 auto; /* Allows the title to grow and shrink */
      text-align: center;
      padding: 0 1rem;
      white-space: nowrap; /* Prevent wrapping */
      overflow: hidden; /* Hide overflow text */
      text-overflow: ellipsis; /* Add ellipsis when overflowing */
      color: #272521;
    }

    .spacer {
      flex: 0 1 1.25rem; /* Shrinkable spacer with an initial width of 50px */
      min-width: 0; /* Allow it to shrink to zero */
    }
  }

  &.shadowless {
    box-shadow: none !important;

    .nav-bar {
      padding-top: 1.8rem;
      padding-bottom: 1rem;
    }
  }
}
</style>
