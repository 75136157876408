<template>
  <n-full-screen overlay>
    <n-theme type="passenger">
      <search-header
        ref="searchHeader"
        :title="$t('main.demand.title')"
        @address-selected="addressSelected"
        @switch-addresses="switchAddresses"
        :from-address="fromAddress"
        :to-address="toAddress"
        :time="departureTime"
        :date-picker-type="
          selectedDemandType === demandType.SINGLE ? 'datetime' : 'time'
        " @departure-time="setDepartureTime" :departure-only="isStopSearch" show-departure-time-tooltip>
        <n-grid class="span-6" :top-gap="3" v-if="isSwitchVisible">
          <n-switch-button class="span-6" v-model="selectedDemandType"
            :text-left="$t('main.demand.switch.createSingle')" :text-right="$t('main.demand.switch.createRecurring')" />
        </n-grid>
      </search-header>
      <portal to="main-map">
        <template v-if="fromAddress !== null && toAddress !== null">

            <map-marker v-if="fromAddress && toAddress" :position="fromAddress" type="plain-outline" theme="passenger"
              faded />

            <map-marker v-if="toAddress && fromAddress" :position="toAddress" theme="passenger" type="end" faded />

            <map-route v-if="toAddress && fromAddress" :points="[toAddress, fromAddress]" theme="passenger" dashed faded />

            <map-marker v-if="validatedLocation && validatedLocation.lat && validatedLocation.lng"
              :key="getMarkerKey('od-location')" :position="validatedLocation" type="plain-outline" theme="passenger"
              faded />

            <map-marker v-if="persistedDestination && persistedDestination.lat && persistedDestination.lng"
              :key="getMarkerKey('od-destination')" :position="persistedDestination" theme="passenger" type="end" faded />

            <map-route v-if="validatedLocation && persistedDestination" key="od-route"
              :points="[validatedLocation, persistedDestination]" theme="passenger" dashed faded />

            <span v-if="(!toAddress && fromAddress) || (toAddress && !fromAddress)" />

          </template>
      </portal>
      <n-bottom-screen v-if="isCreateRecurringVisible">
        <n-layout>
          <div class="span-3"></div>
          <n-button @click="goToCreatePendler" class="span-3" size="lg">{{
            $t('main.demand.recurringContinue')
            }}</n-button>
        </n-layout>
      </n-bottom-screen>
    </n-theme>
    <marketplace-sheet v-if="isDemandSheetVisible" :trips="trips" :streamed="stream != null" :loading="searching"
      :search-params="{
        fromAddress: fromAddress,
        toAddress: toAddress,
        requestId,
      }" :period="period" :shouldTriggerSurvey="shouldTriggerSurvey">
      <div class="span-6" v-if="contains9292Trips">
        <n-layout>
          <n-icon name="9292" size="lg" class="span-1" />
          <n-text class="span-5" preset="sub" color="grey-dark">{{
            $t('marketplace.sheet-bottom.9292')
            }}</n-text>
        </n-layout>
      </div>
      <div class="bg-grey-light span-6" v-if="
          toAddress != null &&
          fromAddress != null &&
          requestId == null &&
          isAuthenticated
        ">
        <n-bottom-safe-spacer>
          <n-layout :bottom-gap="7">
            <n-text class="span-6" preset="header" align="center" color="grey-darker">{{
              $t('marketplace.sheet-bottom.title') }}</n-text>
            <n-text class="span-6" align="center" color="grey-darker">{{
              $t('marketplace.sheet-bottom.description')
              }}</n-text>
            <n-button class="bottom-sheet-button" block type="outlined white" inverted size="lg"
              @click="saveRequest(true)">{{ $t('marketplace.sheet-bottom.button') }}</n-button>
          </n-layout>
        </n-bottom-safe-spacer>
      </div>
    </marketplace-sheet>
  </n-full-screen>
</template>

<script>
import store from '@/store';
import mapsApi from '@/api/maps';
import { Portal } from 'portal-vue';
import constants from '@/constants';
import * as util from '@/vendor/utils';
import commuteApi from '@/api/commute';
import * as mapUtils from '@/vendor/maps';
import { EventBus } from '@/vendor/events';
import { contains9292Trips } from '@/vendor/utils';
import NRoute from '@/components/shared/map/route';
import NMarker from '@/components/shared/map/marker';
import SearchHeader from '@/components/shared/searchHeader';
import {
  parseISO,
  isBefore,
  set,
  addDays,
  differenceInMinutes,
  differenceInSeconds,
} from 'date-fns';
import { unifyPoint, getDistanceBetweenPoints } from '@/vendor/maps';
import MarketplaceSheet from '@/screens/main/demand/marketplaceSheet';
import AddressSearchSheet from '@/components/shared/addressSearchSheet';
import { namespacedTypes as namespacedCommute } from '@/store/modules/commute-types';
import { triggerFormbricksAction } from '@/plugins/Formbricks';
import MapRoute from '@/components/shared/map/route';
import MapMarker from '@/components/shared/map/marker';

let stopIdIncrement = 0;

const demandType = {
  SINGLE: 0,
  RECURRING: 1,
};

export default {
  name: 'mainDemandIndex',
  components: {
    SearchHeader,
    Portal,
    AddressSearchSheet,
    NMarker,
    NRoute,
    MarketplaceSheet,
    MapRoute,
    MapMarker
  },
  props: {
    to: {
      type: Object,
      required: false,
    },
    from: {
      type: Object,
      required: false,
    },
    time: {
      type: Date,
      required: false,
    },
    stop: {
      type: Object,
      required: false,
    },
    requestId: {
      type: Number,
      required: false,
    },
    shouldTriggerSurvey: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      toAddress: this.to,
      fromAddress: this.from,
      departureTimeSingle: this.time,
      departureTimeRecurring: this.time,
      selectedDemandType: demandType.SINGLE,
      sheetType: null,
      routes: [],
      stops: [],
      arrivalStops: [],
      trips: [],
      tripsPeriodStart: null,
      tripsPeriodEnd: null,
      stream: null,
      searching: false,
      lastSearch: null,
      skipRouteLeaveCheck: this.requestId != null,
    };
  },
  computed: {
    demandType() {
      return demandType;
    },
    canSearch() {
      return (
        (this.fromAddress != null && this.toAddress != null) ||
        this.stop != null
      );
    },
    isStopSearch() {
      return this.stop != null;
    },
    isSwitchVisible() {
      return !this.isStopSearch;
    },
    isCreateRecurringVisible() {
      return (
        this.selectedDemandType == demandType.RECURRING &&
        this.fromAddress &&
        this.toAddress
      );
    },
    isDemandSheetVisible() {
      return (
        this.selectedDemandType == demandType.SINGLE &&
        ((this.fromAddress && this.toAddress) ||
          (this.stop && !(this.fromAddress && this.toAddress)))
      );
    },
    firstTrip() {
      const trip = this.trips[0];
      if (trip) {
        if (trip.public) {
          return mapUtils.publicTripToRoutes(this.fromAddress, this.toAddress);
        }
        return mapUtils.combiTripToRoutes(
          trip,
          this.fromAddress,
          this.toAddress
        );
      }
      return {};
    },
    contains9292Trips() {
      return contains9292Trips(this.trips);
    },
    isAuthenticated() {
      return store.getters['user/isLoggedIn'];
    },
    searchParams() {
      return `${this.departureTimeSingle}`;
    },
    currency() {
      if (!this.isAuthenticated) {
        return null;
      }

      return store.state.user.profile.currency;
    },
    departureTime() {
      if (this.selectedDemandType == demandType.SINGLE) {
        return this.departureTimeSingle;
      }

      return this.departureTimeRecurring;
    },
    period() {
      if (!this.tripsPeriodStart || !this.tripsPeriodEnd) {
        return null;
      }

      return {
        start: this.tripsPeriodStart,
        end: this.tripsPeriodEnd,
      };
    },
  },
  mounted() {
    this.setupMapDataWatcher();
    localStorage.setItem('tripSearchStartTime', new Date());
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.stop != null && !to.params.back) {
        vm.setDepartureStop(vm.stop);
        vm.getPlacesObjFromStop(vm.stop, 'from');
        vm.selectedDemandType = demandType.SINGLE;
      }

      if (to.params.from && (!vm.fromAddress || to.params.return)) {
        vm.fromAddress = to.params.from;
      }

      if (to.params.to && (!vm.toAddress || to.params.return)) {
        vm.toAddress = to.params.to;
      }

      if (to.params.single) {
        vm.selectedDemandType = demandType.SINGLE;
      }

      if (!to.params.back && !to.params.time) {
        vm.setDefaultDepartureTimes();
        return;
      }

      // Ensure we show the save lift agent dialog when coming back from trip details
      if (from.name === 'trip-details-search') {
        vm.skipRouteLeaveCheck = false;
      }

      const keepResultThreshold = 2;
      const searchInBackgroundThreshold = 15;
      const minutesSinceLastSearch = differenceInMinutes(
        new Date(),
        vm.lastSearch
      );
      const shouldNotReusePreviousResult =
        from.name !== 'trip-details-search' ||
        (from.name === 'trip-details-search' &&
          minutesSinceLastSearch > keepResultThreshold);
      const shouldFetchResultInBackground =
        from.name === 'trip-details-search' &&
        minutesSinceLastSearch < keepResultThreshold &&
        minutesSinceLastSearch > searchInBackgroundThreshold;

      if (shouldFetchResultInBackground) {
        vm.search(true);
      } else if (shouldNotReusePreviousResult) {
        vm.search();
      }
    });
  },
  beforeRouteLeave(to, from, next) {
    // Trip details / create pendler demand / from login sheet
    if (
      to.name.includes('trip-details') ||
      to.name.includes('demand.options') ||
      to.name.includes('auth')
    ) {
      this.skipRouteLeaveCheck = true;
    }

    if (
      this.fromAddress &&
      this.toAddress &&
      !this.skipRouteLeaveCheck &&
      this.isAuthenticated
    ) {
      const sessionStartedAt = new Date(
        localStorage.getItem('sessionStartTime')
      );
      const tripSearchStartedAt = new Date(
        localStorage.getItem('tripSearchStartTime')
      );

      const attributes = {
        secondsSinceSessionStarted: differenceInSeconds(
          new Date(),
          sessionStartedAt
        ),
        secondsSinceSearchStarted: differenceInSeconds(
          new Date(),
          tripSearchStartedAt
        ),
        geoInformation: JSON.stringify({
          from: this.fromAddress,
          to: this.toAddress,
        }),
      };

      this.$modal.show('dialog', {
        title: this.$t('marketplace.request-dialog.title'),
        text: this.$t('marketplace.request-dialog.description'),
        cancel: true,
        success: {
          text: this.$t('marketplace.request-dialog.success'),
          handler: () => {
            triggerFormbricksAction(
              'Action:SaveRideAgentPrompt:Accepted',
              attributes
            );

            this.saveRequest(
              false,
              // success handler
              () => {
                next();
                this.$success(this.$t('marketplace.request-dialog.snackbar'));
              },
              // cancelled handler
              next
            );
          },
        },
        cancelButton: {
          text: this.$t('marketplace.request-dialog.cancel'),
          handler: () => {
            triggerFormbricksAction(
              'Action:SaveRideAgentPrompt:Rejected',
              attributes
            );

            next();
          },
        },
      });
    } else {
      next();
    }
  },
  watch: {
    searchParams() {
      // Also searches on adress changes
      // Also searches on address changes
      this.search();
    },
    departureTimeSingle(value) {
      if (this.selectedDemandType == demandType.SINGLE) {
        this.$refs.searchHeader.setDepartureTime(value);
      }
    },
    departureTimeRecurring(value) {
      if (this.selectedDemandType == demandType.RECURRING) {
        this.$refs.searchHeader.setDepartureTime(value);
      }
    },
    selectedDemandType(value) {
      this.$refs.searchHeader.setDepartureTime(
        value === demandType.SINGLE
          ? this.departureTimeSingle
          : this.departureTimeRecurring
      );
    },
  },
  methods: {
    setDepartureTime(time) {
      if (time === null) {
        return;
      }

      if (this.selectedDemandType === demandType.SINGLE) {
        this.departureTimeSingle = time;
      } else {
        this.departureTimeRecurring = time;
      }
    },
    getDefaultDepartureTimeSingle() {
      return new Date();
    },
    getDefaultDepartureTimeRecurring() {
      const { daysOffset, hours, minutes } =
        constants.defaultTripTimes.departure;

      return set(addDays(new Date(), daysOffset), {
        hours,
        minutes,
      });
    },
    setDefaultDepartureTimes() {
      this.departureTimeSingle = null;
      this.departureTimeRecurring = this.getDefaultDepartureTimeRecurring();
    },
    setDepartureStop(stop) {
      this.fromAddress = {
        ...stop,
        stop,
        completeAddress: stop.name,
      };
    },
    setDestinationStop(stop) {
      this.toAddress = {
        ...stop,
        stop,
        completeAddress: stop.name,
      };
    },
    getMarkerKey(id) {
      // due to rendering issues with google maps marker, the key
      // is incremented for every render
      return `${id}_${stopIdIncrement++}`;
    },
    /**
     * This generally does not seem like the best way to achive this, then actual map data is displayed by portal
     * Watcher for when data changes, tell the map to change it bounds.
     */
    setupMapDataWatcher() {
      this.$watch(
        (vm) => vm[(vm.toAddress, vm.fromAddress, vm.stops, vm.arrivalStops)],
        (val) => {
          let positions = [
            this.toAddress,
            this.fromAddress,
            ...this.arrivalStops,
            ...this.stops,
          ];
          this.$emit('positions', positions);
        },
        {
          immediate: true,
          deep: true,
        }
      );
    },
    /**
     * Swap from and to addresses
     */
    switchAddresses() {
      [this.toAddress, this.fromAddress] = [this.fromAddress, this.toAddress];
      if (!this.fromAddress) {
        // from = stop, to = null - switch
        this.clearData();
      } else if (this.fromAddress && !this.toAddress) {
        // from = null, to = stop - show all the data again
        this.clearData();
        if (this.fromAddress.id != null) {
          this.showRoutesFromStop();
          this.getPlacesObjFromStop(this.stop, 'from');
        }
      } else {
        this.search();
      }
    },
    openSheet(type) {
      this.sheetType = type;
      this.$refs.searchSheet.open();
    },
    /**
     * Callback for sheet addresses selected, triggers search
     */
    addressSelected(adr) {
      if (adr.sheetType == 'from') {
        this.fromAddress = adr;
      } else {
        this.toAddress = adr;
      }
      // Can't mix stops and addresses on the map
      // Clear the stops and do a normal search
      this.clearData();
      this.search();
    },
    onStream(stream, clearDataOnFirstResult = false) {
      this.stream = stream;
      this.stream.addEventListener('period', this.periodReceived, false);
      this.stream.addEventListener(
        'message',
        this.wrappedTripsReceived(clearDataOnFirstResult),
        false
      );
      this.stream.addEventListener('error', this.handleStreamError, false);
      this.stream.addEventListener('done', this.closeStream, false);
    },
    /**
     * Search trips
     */
    search(runInBackground = false) {
      if (!this.canSearch) {
        return;
      }

      if (!runInBackground) {
        this.trips = [];
        this.searching = true;
      }

      this.lastSearch = new Date();

      if (this.stream) {
        this.stream.close();
      }

      if (
        (this.fromAddress == null || this.fromAddress.stop !== null) &&
        this.stop != null
      ) {
        this.showRoutesFromStop();
        return;
      }

      let request = {
        ...util.prefixKeys(this.toAddress.parts, 'arrival'),
        ...util.prefixKeys(this.fromAddress.parts, 'departure'),
        departure_time: (this.departureTime
          ? this.departureTime
          : new Date()
        ).toISOString(),
        currency: this.currency,
      };

      this.onStream(commuteApi.marketplaceTripSearch(request), runInBackground);
    },
    /**
     * When clicked on a stop from the main page
     * Shows all routes through that stop, filtered for uniqueness.
     */
    showRoutesFromStop() {
      this.onStream(commuteApi.tripsFromStop(this.stop.id));
    },
    handleStreamError(err) {
      let errKeys = [];

      try {
        errKeys = Object.keys(JSON.parse(err.data));
      } catch (e) {}

      if (
        errKeys.length > 0 &&
        this.$te(`marketplace.validationErrors.${errKeys[0]}`)
      ) {
        this.$notify(this.$t(`marketplace.validationErrors.${errKeys[0]}`));
      } else {
        this.$error();
      }

      this.stream.close();
      this.searching = false;
    },
    closeStream() {
      this.stream.close();
      this.searching = false;
    },
    periodReceived(e) {
      let period = null;

      try {
        period = JSON.parse(e.data);
      } catch (e) {
        this.tripsPeriodStart = null;
        this.tripsPeriodEnd = null;
      }

      let { start, end } = period;

      if (start) {
        start = parseISO(start);
      }

      if (start && isBefore(start, new Date())) {
        start = new Date();
      }

      if (end) {
        end = parseISO(end);
      }

      this.tripsPeriodStart = start;
      this.tripsPeriodEnd = end;
    },
    wrappedTripsReceived(clearDataOnFirstResult = false) {
      let hasHadFirstResult = false;
      return (data) => {
        if (clearDataOnFirstResult && !hasHadFirstResult) {
          hasHadFirstResult = true;
          this.trips = [];
        }
        this.tripsReceived(data);
      };
    },
    tripsReceived({ data }) {
      if (data === 'done') {
        return (this.searching = false);
      }

      const incomingTrips = JSON.parse(data);
      const existingTrips = [...this.trips];

      incomingTrips.forEach((trip) => {
        const aboveTrip = existingTrips.find(
          (existingTrip) => existingTrip.id === trip.position?.above
        );
        const insertionIndex = aboveTrip
          ? existingTrips.indexOf(aboveTrip)
          : existingTrips.length;
        existingTrips.splice(insertionIndex, 0, trip);

        trip.position.replaces.forEach((replaceTripId) => {
          const replacedTripIndex = existingTrips.findIndex(
            (existingTrip) => existingTrip.id === replaceTripId
          );
          if (replacedTripIndex !== -1) {
            existingTrips.splice(replacedTripIndex, 1);
          }
        });

        if (!trip.public) {
          this.routes.push(
            mapUtils.sliceRoute(
              this.fromAddress,
              this.toAddress,
              window.google.maps.geometry.encoding.decodePath(
                trip.driver_trip_details.route
              )
            )
          );
        }
      });

      this.trips = existingTrips;
    },
    /**
     * When a stop here is selected
     * Inserts into an empty address object
     */
    async selectStop(stop) {
      if (this.isStopSearch) {
        return;
      }

      this.clearData();
      if (!this.toAddress) {
        this.setDestinationStop(stop);
        await this.getPlacesObjFromStop(stop, 'to');
      } else if (!this.fromAddress) {
        this.setDepartureStop(stop);
        await this.getPlacesObjFromStop(stop, 'from');
      }
      this.search();
    },
    /**
     * Get the place object from Google to keep the data persistent in the same format
     */
    async getPlacesObjFromStop(stop, dir) {
      const placeObj = (
        await mapsApi.reverseGeocodeLatLng({ lat: stop.lat, lng: stop.lng })
      ).results[0];
      if (dir === 'to') {
        this.toAddress = {
          ...util.parseMapsToLocation(placeObj),
          completeAddress: stop.name,
          stop,
        };
      } else if (dir === 'from') {
        this.fromAddress = {
          ...util.parseMapsToLocation(placeObj),
          completeAddress: stop.name,
          stop,
        };
      }
    },
    /**
     * Create a pendler profile
     */
    goToCreatePendler() {
      if (!this.isAuthenticated) {
        EventBus.$emit('show-login-sheet', {
          doAfterLogin: this.pushCreatePendler,
        });
        return;
      }
      this.pushCreatePendler();
    },
    pushCreatePendler() {
      this.$router.push({
        name: 'demand.options',
        params: {
          trip: this.parsedData(),
          time: this.departureTime ? this.departureTime : new Date(),
          from: this.fromAddress,
          to: this.toAddress,
        },
      });
    },
    /**
     * Convert fromtend format to backend format
     */
    parsedData() {
      return util.parseTripDataV4(
        {
          fromAddress: this.fromAddress,
          toAddress: this.toAddress,
          departureTime: this.departureTime,
        },
        false
      );
    },
    /**
     * Resets all the data from stop clicking state of the view
     */
    clearData() {
      // TODO: maybe keep all the data another place. If swapping the addreses back and forth, all the data is needed again.
      this.$nextTick(() => {
        this.routes = [];
        this.stops = [];
        this.arrivalStops = [];
        this.trips = [];
      });
    },
    saveRequest(
      explicit,
      successCallback = () => {},
      cancelledCallback = () => {}
    ) {
      const proceedHandler = () => {
        this.$store.dispatch(namespacedCommute.CREATE_COMMUTE_REQUEST, {
          ...this.parsedData(),
        });

        successCallback();

        if (explicit) {
          this.skipRouteLeaveCheck = true;
          this.$success(this.$t('marketplace.save-request.text'));
        }
      };

      // check whether trip is below 50 meters, and in that case show pop-up
      const data = this.parsedData();
      const from = unifyPoint({ lat: data.from_lat, lng: data.from_lng });
      const to = unifyPoint({ lat: data.to_lat, lng: data.to_lng });

      if (getDistanceBetweenPoints(from, to) < 50) {
        setTimeout(() => {
          this.$modal.show('dialog', {
            title: this.$t('marketplace.short-trip-dialog.title'),
            text: this.$t('marketplace.short-trip-dialog.description'),
            cancel: true,
            success: {
              text: this.$t('marketplace.short-trip-dialog.success'),
              handler: proceedHandler,
            },
            cancelButton: {
              text: this.$t('marketplace.short-trip-dialog.cancel'),
              handler: cancelledCallback,
            },
          });
        }, 500);
      } else {
        proceedHandler();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.backbutton {
  position: absolute;
  top: 40px;
  left: 15px;
}
.switch {
  position: absolute;
  top: 102px;
  right: 15px;
}
.bg-grey-light {
  background: var(--color-grey-light);
}

.bottom-sheet-button {
  padding-top: 1rem;
}
</style>
