<template>
  <n-full-screen overlay>
    <n-top-screen v-if="!trip">
      <n-container preset="top">
        <n-theme type="driver">
          <n-layout>
            <n-spinner class="span-6 flex-center" />
          </n-layout>
        </n-theme>
        <portal to="main-map">
          <span />
          <!-- Portal has data (not showing default content) -->
        </portal>
      </n-container>
    </n-top-screen>
    <n-theme type="driver" v-else>
      <search-header
        :title="$t('edit.trip.title')"
        @address-selected="addressSelected"
        @switch-addresses="switchAddresses"
        :from-address="fromAddress"
        :to-address="toAddress"
        :time="new Date(trip.planned_departure)"
        @departure-time="trip.departureTime = $event"
      >
        <template v-slot:options>
          <n-column :span="2">
            <seat-selector v-model="seats" />
          </n-column>
          <n-column :span="2">
            <charge-for-rides-selector v-model="chargeForTrip" />
          </n-column>
        </template>
      </search-header>
      <n-bottom-screen>
        <n-layout
          v-if="
            toAddress != null &&
            fromAddress != null &&
            Object.keys(route.path).length != 0
          "
        >
          <n-button
            class="span-2"
            @click="cancelTrip"
            color="error"
            type="outlined white"
            inverted
            >{{ $t('edit.trip.delete') }}</n-button
          >
          <n-button
            class="span-2"
            @click="adjustRoute"
            type="outlined white"
            inverted
            >{{ $t('edit.trip.adjust') }}</n-button
          >
          <n-button :loading="loading" @click="save" class="span-2">{{
            $t('edit.trip.save')
          }}</n-button>
        </n-layout>
      </n-bottom-screen>
      <portal to="main-map">
        <n-marker
          theme="driver"
          v-if="fromAddress"
          type="start"
          :position="{ lat: fromAddress.lat, lng: fromAddress.lng }"
        />
        <n-marker
          theme="driver"
          v-if="toAddress"
          type="end"
          :position="{ lat: toAddress.lat, lng: toAddress.lng }"
        />
        <n-marker
          @click="stopClick(stop)"
          :theme="stop.deactivated ? 'deactivated' : 'driver'"
          v-for="stop in route.stops"
          :key="getKey(stop.id)"
          :position="stop"
        />
        <n-route :points="route.path" />
      </portal>
      <add-remove-stop-dialog ref="addRemoveDialog" />
      <cancel-driver-trip-dialog ref="cancelDialog" />
    </n-theme>
    <route-adjuster-sheet ref="routeAdjuster" @selected="onAdjust" />
  </n-full-screen>
</template>

<script>
import SeatSelector from '@/components/shared/seatSelector';
import { Portal } from 'portal-vue';
import commuteApi from '@/api/commute';
import NMarker from '@/components/shared/map/marker';
import NRoute from '@/components/shared/map/route';
import RouteAdjusterSheet from '@/sheets/routeAdjusterSheet';
import SearchHeader from '@/components/shared/searchHeader';
import AddRemoveStopDialog from '@/dialogs/addRemoveStopDialog';
import CancelDriverTripDialog from '@/dialogs/cancelDriverTripDialog';
import {
  parseTripToLocation,
  isSameAddress,
  removeUnderscorePrefixFromKeys,
} from '@/vendor/utils';
import { format } from '@/vendor/date-fns';
import { mapActions } from 'vuex';
import { namespacedTypes as userTypes } from '@/store/modules/user-types';

import ChargeForRidesSelector from '@/components/shared/chargeForRidesSelector.vue';

let stopIdIncrement = 0;

export default {
  name: 'mainEditDriverTrip',
  components: {
    RouteAdjusterSheet,
    Portal,
    NRoute,
    SearchHeader,
    NMarker,
    AddRemoveStopDialog,
    CancelDriverTripDialog,
    SeatSelector,
    ChargeForRidesSelector,
  },
  props: {
    driverTrip: {
      type: Object,
      default: null,
    },
    id: {
      type: String,
      default: null,
    },
  },
  data: () => {
    return {
      hasRouteChanged: false,
      fromAddress: null,
      toAddress: null,
      trip: null,
      seats: 2,
      loading: false,
      route: {
        stops: [],
        path: [],
        distance: null,
        duration: null,
      },
      waypoints: [],
      originalForWaypoint: null,
      chargeForTrip: true,
    };
  },
  async mounted() {
    const id = this.driverTrip ? this.driverTrip.id : this.id;
    this.trip = await this.fetchSingleTrip(id);

    this.seats = this.trip.seats.id ? this.trip.seats.id : this.trip.seats;
    this.chargeForTrip = this.trip.charge_for_trip;
    const parsed = parseTripToLocation(this.trip);
    this.fromAddress = parsed.fromAddress;
    this.toAddress = parsed.toAddress;
    this.waypoints = this.trip.driver_stops
      .filter(
        (x) =>
          x.via_stop &&
          x.id != this.trip.from_via_stop_id &&
          x.id != this.trip.to_via_stop_id
      )
      .map((x) => ({
        address: x,
      }));

    this.route = {
      path: this.trip.route,
      distance: this.trip.distance,
      duration: this.trip.duration,
      stops: this.trip.driver_stops.filter((x) => !x.via_stop),
    };
    this.$emit('positions', [this.fromAddress, this.toAddress]);
  },
  methods: {
    ...mapActions({
      fetchSingleTrip: userTypes.FETCH_SINGLE_COMMUTE,
    }),
    getKey(id) {
      // due to rendering issues with google maps marker, the key
      // is incremented for every render
      return `${id}_${stopIdIncrement++}`;
    },
    /**
     * @param {Point} origin
     * @param {Point} destination
     */
    async fetchRoute(origin, destination) {
      return await commuteApi.getRouteBetweenPoints(origin, destination);
    },

    /**
     * @param {AddressSelection} address
     */
    addressSelected(address) {
      /** @type {Address} */
      const addressInformation = {
        ...address,
        ...removeUnderscorePrefixFromKeys(address.parts),
      };

      if (addressInformation.sheetType == 'from') {
        this.fromAddress = addressInformation;
      } else {
        this.toAddress = addressInformation;
      }

      this.setRoute(this.toAddress, this.fromAddress);
    },
    async setRoute(toAddress, fromAddress) {
      this.unsetRoute();
      if (isSameAddress(fromAddress, toAddress)) {
        this.$error(this.$t('error.addresses_must_be_different'));
        this.unsetRoute();
        return;
      }

      if (toAddress == null || fromAddress == null) {
        return;
      }

      this.$emit('positions', [fromAddress, toAddress]);
      this.route = await this.fetchRoute(fromAddress, toAddress);
      this.hasRouteChanged = true;

      if (!this.route) {
        this.$error(this.$t('error.unable_to_create_route'));
      }
    },
    unsetRoute() {
      this.route = {
        stops: [],
        path: [],
      };
    },
    switchAddresses() {
      [this.toAddress, this.fromAddress] = [this.fromAddress, this.toAddress];
      this.setRoute(this.toAddress, this.fromAddress);
    },
    async adjustRoute() {
      if (!this.originalForWaypoint) {
        this.originalForWaypoint = {
          route: this.route,
          start: this.fromAddress,
          stop: this.toAddress,
        };
      }

      this.$refs.routeAdjuster.open({
        route: this.route,
        start: this.fromAddress,
        stop: this.toAddress,
        waypoints: this.waypoints,
        original: this.originalForWaypoint,
      });
    },
    onAdjust(data) {
      this.route = data.route;
      this.waypoints = data.waypoints;
      this.hasRouteChanged = true;
    },
    stopClick(stop) {
      const index = this.route.stops.findIndex((x) => x.id === stop.id);
      this.$refs.addRemoveDialog.show(stop, () => {
        stop.deactivated = stop.deactivated ? false : true;
        this.$set(this.route.stops, index, stop);
      });
    },
    async save() {
      if (this.loading) {
        return;
      }

      const from = this.fromAddress;
      const to = this.toAddress;
      this.loading = true;

      const dto = {
        id: this.trip.id,
        type: 'SINGLE_TRIP',
        charge_for_trip: this.chargeForTrip,
        departure: format(this.trip.departureTime),
        seats: this.seats,
        via_stops: this.waypoints.map((x) => x.address),
        departure_stops: this.route.stops
          .filter((x) => !x.deactivated)
          .map((x) => x.id),

        from_country: from.country,
        from_street: from.street,
        from_zipcode: from.zipcode,
        from_city: from.city,
        from_lat: from.lat,
        from_lng: from.lng,
        from_place_id: from.place_id,

        to_country: to.country,
        to_street: to.street,
        to_zipcode: to.zipcode,
        to_city: to.city,
        to_lat: to.lat,
        to_lng: to.lng,
        to_place_id: to.place_id,
      };
      if (this.hasRouteChanged) {
        dto.departure_route = this.route.path;
        dto.departure_distance = this.route.distance;
        dto.departure_duration = this.route.duration;
        dto.departure_route_steps = this.route.steps;
      }

      await commuteApi.updateDriverTrip(dto);
      this.loading = false;
      this.$router.back();
      this.$success(this.$t('edit.trip.changes-saved'));
    },
    cancelTrip() {
      this.$refs.cancelDialog.show(
        this.trip.id,
        // success callback
        () => {
          this.$success(this.$t('dialog.cancelDriverTrip.snackbar'));
          this.$router.back();
        },
        // error callback
        () => {
          this.$error(this.$t('dialog.cancelDriverTrip.cancellingTripError'));
        }
      );
    },
  },
};
</script>
