<template>
  <n-full-screen overlay>
    <n-theme type="driver">
      <search-header
        ref="searchHeader"
        :title="$t('main.provide.title')"
        @address-selected="addressSelected"
        @switch-addresses="switchAddresses"
        :from-address="fromAddress"
        :to-address="toAddress"
        :date-picker-type="isSingleTrip ? 'datetime' : 'time'"
        @departure-time="setDepartureTime"
      >
        <template v-slot:options>
          <n-column :span="2">
            <seat-selector v-model="selectedSeats" />
          </n-column>
          <n-column :span="2">
            <charge-for-rides-selector v-model="chargeForTrip" />
          </n-column>
        </template>
        <template v-slot:default>
          <n-grid class="span-6" :top-gap="3">
            <n-switch-button
              v-model="selectedTripType"
              class="span-6"
              :text-left="$t('main.provide.pendlerTrip')"
              :text-right="$t('main.provide.singleTrip')"
            />
          </n-grid>
        </template>
      </search-header>
      <n-bottom-screen v-if="shouldShowBottomScreen">
        <n-layout>
          <n-button
            @click="adjustRoute"
            class="span-3"
            size="lg"
            type="outlined white"
            inverted
            >{{ $t('main.provide.adjustRoute') }}</n-button
          >
          <n-button
            @click="create"
            :loading="createLoading"
            v-if="isSingleTrip"
            class="span-3"
            size="lg"
            >{{ $t('main.provide.offerTrip') }}</n-button
          >
          <n-button
            @click="next"
            v-if="isRecurringTrip"
            class="span-3"
            size="lg"
            >{{ $t('main.provide.next') }}</n-button
          >
        </n-layout>
      </n-bottom-screen>
      <portal to="main-map">
        <span v-if="fromAddress && toAddress">
          <n-marker
            theme="driver"
            :position="{ lat: fromAddress.lat, lng: fromAddress.lng }"
            type="start"
          />
          <n-marker
            theme="driver"
            type="end"
            :position="{ lat: toAddress.lat, lng: toAddress.lng }"
          />
        </span>
        <n-marker
          @click="stopClick(stop)"
          :theme="stop.deactivated ? 'deactivated' : 'driver'"
          v-for="stop in route.stops"
          :key="getMarkerKey(stop.id)"
          :position="stop"
        />
        <n-route :points="route.path" />
      </portal>
    </n-theme>
    <route-adjuster-sheet ref="routeAdjuster" @selected="onAdjust" />
    <offer-return-trip-dialog ref="offerReturnTripDialog" />
    <add-remove-stop-dialog ref="addRemoveDialog" />
  </n-full-screen>
</template>

<script>
import SeatSelector from '@/components/shared/seatSelector';
import constants from '@/constants';
import { Portal } from 'portal-vue';
import * as util from '@/vendor/utils';
import commuteApi from '@/api/commute';
import NRoute from '@/components/shared/map/route';
import NMarker from '@/components/shared/map/marker';
import { set, addDays } from 'date-fns';
import SearchHeader from '@/components/shared/searchHeader';
import RouteAdjusterSheet from '@/sheets/routeAdjusterSheet';
import AddRemoveStopDialog from '@/dialogs/addRemoveStopDialog';
import OfferReturnTripDialog from '@/dialogs/offerReturnTripDialog';
import i18n from '@/i18n/index';
import ChargeForRidesSelector from '@/components/shared/chargeForRidesSelector.vue';
import { format } from '@/vendor/date-fns';
import config from '@shared/config.json';
import { ignoreHandledErrors } from '@/vendor/axios';

let stopIdIncrement = 0;

const provideType = {
  RECURRING: 0,
  SINGLE: 1,
};

export default {
  name: 'mainProvideIndex',
  components: {
    SeatSelector,
    RouteAdjusterSheet,
    Portal,
    SearchHeader,
    NMarker,
    NRoute,
    AddRemoveStopDialog,
    OfferReturnTripDialog,
    ChargeForRidesSelector,
  },
  props: {
    from: {
      type: Object,
      required: false,
    },
    to: {
      type: Object,
      required: false,
    },
    return: {
      type: Boolean,
      default: false,
    },
    type: {
      type: Number,
      required: false,
    },
    fromAddressPlaceId: {
      type: String,
      required: false,
    },
    toAddressPlaceId: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      toAddress: this.to,
      fromAddress: this.from,
      distanceMeetsMinimumRequirement: false,
      chargeForTrip: true,
      selectedSeats: 3,
      selectedTripType: provideType.RECURRING,
      departureTimeSingle: null,
      departureTimeRecurring: null,
      sheetType: null,
      hasOfferedReturnTrip: false,
      route: {
        steps: [],
        stops: [],
        distance: null,
        duration: null,
        path: [],
      },
      waypoints: [],
      createLoading: false,
      originalForWaypoint: null,
      routeLoading: true,
    };
  },
  computed: {
    isSingleTrip() {
      return this.selectedTripType == provideType.SINGLE;
    },
    isRecurringTrip() {
      return this.selectedTripType == provideType.RECURRING;
    },
    addressesAreValid() {
      return (
        this.fromAddress &&
        this.toAddress &&
        !util.isSameAddress(this.fromAddress, this.toAddress)
      );
    },
    departureTime() {
      return this.isSingleTrip
        ? this.departureTimeSingle
        : this.departureTimeRecurring;
    },
    shouldShowBottomScreen() {
      return (
        !this.routeLoading &&
        this.addressesAreValid &&
        this.distanceMeetsMinimumRequirement
      );
    },
  },
  mounted() {
    this.setupMapDataWatcher();
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (to.params.from) {
        vm.fromAddress = to.params.from;
      }

      if (to.params.to) {
        vm.toAddress = to.params.to;
      }

      if (vm.fromAddress && vm.toAddress) {
        vm.setRoute(vm.toAddress, vm.fromAddress);
      }

      if (to.params.type) {
        vm.selectedTripType = to.params.type;
      }

      if (from.name?.indexOf('provide.options') === -1) {
        vm.setDefaultDepartureTimes(to.params.return === true);
        vm.originalForWaypoint = null;
      }
    });
  },
  watch: {
    departureTimeSingle(value) {
      if (this.isSingleTrip) {
        this.$refs.searchHeader.setDepartureTime(value);
      }
    },
    departureTimeRecurring(value) {
      if (this.isRecurringTrip) {
        this.$refs.searchHeader.setDepartureTime(value);
      }
    },
    selectedTripType(value) {
      const departureTime = this.isSingleTrip
        ? this.departureTimeSingle
        : this.departureTimeRecurring;
      this.$refs.searchHeader.setDepartureTime(departureTime);
    },
  },
  methods: {
    getDefaultDepartureTimeSingle(returnTrip) {
      if (returnTrip) {
        const { hours, minutes } = constants.defaultTripTimes.return;

        const time = set(new Date(), {
          hours,
          minutes,
          seconds: 0,
          milliseconds: 0,
        });

        return time < new Date() ? addDays(time, 1) : time;
      }

      return null;
    },
    getDefaultDepartureTimeRecurring(returnTrip) {
      const { daysOffset, hours, minutes } = returnTrip
        ? constants.defaultTripTimes.return
        : constants.defaultTripTimes.departure;

      return set(addDays(new Date(), daysOffset), {
        hours,
        minutes,
      });
    },
    setDepartureTime(time) {
      if (time === null) {
        return;
      }

      if (this.isSingleTrip) {
        this.departureTimeSingle = time;
      } else {
        this.departureTimeRecurring = time;
      }
    },
    setDefaultDepartureTimes(returnTrip = false) {
      this.departureTimeSingle = this.getDefaultDepartureTimeSingle(returnTrip);
      this.departureTimeRecurring =
        this.getDefaultDepartureTimeRecurring(returnTrip);
    },
    getMarkerKey(id) {
      // due to rendering issues with google maps marker, the key
      // is incremented for every render
      return `${id}_${stopIdIncrement++}`;
    },
    /**
     * This generally does not seem like the best way to achive this, then actual map data is displayed by portal
     * Watcher for when data changes, tell the map to change it bounds.
     */
    setupMapDataWatcher() {
      this.$watch(
        (vm) => vm[(vm.toAddress, vm.fromAddress, vm.route.stops)],
        (val) => {
          let positions = [
            this.toAddress,
            this.fromAddress,
            ...this.route.stops,
          ];
          this.$emit('positions', positions);
        },
        {
          immediate: true,
          deep: true,
        }
      );
    },
    switchAddresses() {
      [this.toAddress, this.fromAddress] = [this.fromAddress, this.toAddress];
      this.setRoute(this.toAddress, this.fromAddress);
    },
    addressSelected(adr) {
      if (adr.sheetType == 'from') {
        this.fromAddress = adr;
      } else {
        this.toAddress = adr;
      }

      this.setRoute(this.toAddress, this.fromAddress);
    },
    /**
     *
     * @param {Point} origin
     * @param {Point} destination
     */
    async fetchRoute(origin, destination) {
      return await commuteApi.getRouteBetweenPoints(origin, destination);
    },
    async setRoute(toAddress, fromAddress) {
      this.unsetRoute();
      if (util.isSameAddress(fromAddress, toAddress)) {
        return;
      }

      if (toAddress == null || fromAddress == null) {
        return;
      }

      this.distanceMeetsMinimumRequirement = false;
      this.routeLoading = true;
      try {
        this.route = await this.fetchRoute(fromAddress, toAddress);
        this.distanceMeetsMinimumRequirement = true;
      } catch (e) {
        if (e?.response?.data?.error === 'route_too_short') {
          this.$error(
            this.$t(`error.route_too_short`, {
              kilometers: i18n.n(
                config.minimum_trip_length_in_meter / 1000,
                'km'
              ),
            })
          );
          return;
        }
        if (e?.response?.status) {
          this.$error(this.$t(`error.unable_to_create_route`));
        }
      } finally {
        this.routeLoading = false;
      }
      this.routeLoading = false;
    },
    unsetRoute() {
      this.originalForWaypoint = null;
      this.waypoints = [];
      this.route = {
        steps: [],
        stops: [],
        distance: null,
        duration: null,
        path: [],
      };
    },
    stopClick(stop) {
      const index = this.route.stops.findIndex((x) => x.id === stop.id);
      this.$refs.addRemoveDialog.show(stop, () => {
        stop.deactivated = stop.deactivated ? false : true;
        this.$set(this.route.stops, index, stop);
      });
    },
    next() {
      this.$router.push({
        name: 'provide.options',
        params: {
          trip: this.generateDTO({ type: 'OTHER' }),
          time: this.departureTime ? this.departureTime : new Date(),
          from: this.fromAddress,
          to: this.toAddress,
          return: this.return,
        },
      });
    },
    create() {
      if (this.createLoading) {
        return;
      }
      this.createLoading = true;

      commuteApi
        .postDriverTrip(this.generateDTO({ type: 'SINGLE_TRIP' }))
        .then(this.onSavedSuccessfully)
        .catch(ignoreHandledErrors)
        .finally(() => {
          this.createLoading = false;
        });
    },
    onSavedSuccessfully() {
      if (this.return || this.hasOfferedReturnTrip) {
        this.onDontCreateReturnTrip();
        return;
      }

      this.hasOfferedReturnTrip = true;

      this.$refs.offerReturnTripDialog.show(
        this.onCreateReturnTrip,
        this.onDontCreateReturnTrip
      );
    },
    onCreateReturnTrip() {
      this.switchAddresses();
      this.setDefaultDepartureTimes(true);

      this.$nextTick(() => {
        this?.$refs.searchHeader?.triggerDatePicker();
      });
    },
    onDontCreateReturnTrip() {
      this.$router.resetToHome();
      this.$success(i18n.t('main.provide.singleTripCreated'));
    },
    adjustRoute() {
      if (!this.originalForWaypoint) {
        this.originalForWaypoint = {
          route: this.route.path,
          stops: this.route.stops,
          start: this.fromAddress,
          stop: this.toAddress,
        };
      }

      this.$refs.routeAdjuster.open({
        route: this.route,
        start: this.fromAddress,
        stop: this.toAddress,
        waypoints: this.waypoints,
        original: this.originalForWaypoint,
      });
    },
    onAdjust(data) {
      this.route = data.route;
      this.waypoints = data.waypoints;
    },
    generateDTO({ type }) {
      const from = this.fromAddress.transformed
        ? this.fromAddress.parts
        : util.getAllAddressComponents(this.fromAddress.placeObject);

      const to = this.toAddress.transformed
        ? this.toAddress.parts
        : util.getAllAddressComponents(this.toAddress.placeObject);

      return {
        type,
        charge_for_trip: this.chargeForTrip,
        departure: format(this.departureTime ?? new Date()),
        departure_distance: this.route.distance,
        departure_duration: this.route.duration,
        departure_route: this.route.path,
        departure_route_steps: this.route.steps,
        departure_stops: this.route.stops
          .filter((x) => !x.deactivated)
          .map((x) => x.id),
        seats: this.selectedSeats,
        via_stops: this.waypoints.map((x) => x.address),

        from_country: from.country,
        from_street: from.street,
        from_zipcode: from.zipcode,
        from_city: from.city,
        from_lat: from.lat,
        from_lng: from.lng,
        from_place_id: from.place_id,

        to_country: to.country,
        to_street: to.street,
        to_zipcode: to.zipcode,
        to_city: to.city,
        to_lat: to.lat,
        to_lng: to.lng,
        to_place_id: to.place_id,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.backbutton {
  position: absolute;
  top: 40px;
  left: 15px;
}

.switch {
  position: absolute;
  top: 102px;
  right: 15px;
}
</style>
